import { InstallXOptions, SnippetConfig } from '@empathyco/x-components';
import { cssInjector, I18n } from '@empathyco/x-archetype-utils';
import { default as AppComponent } from '../App.vue';
import * as messages from '../i18n/messages';
import store from '../store';
import { adapter } from '../adapter/adapter';
import { useDevice } from '../composables/use-device.composable';
import { searchInstallOptions } from '../x-modules/search.options';

const device = useDevice();

/**
 * Function that returns the options to install x-components.
 *
 * @returns - The InstallXOptions.
 */
export function getInstallXOptions(): InstallXOptions {
  return {
    adapter,
    store,
    rootComponent: AppComponent,
    domElement: getDomElement,
    xModules: {
      facets: {
        config: {
          filtersStrategyForRequest: 'leaves-only'
        }
      },
      semanticQueries: {
        config: {
          threshold: 50,
          maxItemsToRequest: 10
        }
      },
      identifierResults: {
        config: {
          identifierDetectionRegexp: '^([a-zA-Z]{1,}[0-9]{3,})'
        }
      },
      search: searchInstallOptions
    },
    async installExtraPlugins({ app, snippet }) {
      const i18n = await I18n.create({
        locale: snippet.uiLang,
        device: (snippet.device as string) ?? device.deviceName.value,
        fallbackLocale: 'en',
        messages
      });
      app.use(i18n);
      app.config.globalProperties.$setLocale = i18n.setLocale.bind(i18n);
      app.config.globalProperties.$setLocaleDevice = i18n.setDevice.bind(i18n);
    }
  };
}

/**
 * Creates a DOM element to mount the X Components app.
 *
 * @param snippetConfig - The snippet configuration.
 * @returns The DOM element.
 */
function getDomElement({ isolate }: SnippetConfig): Element {
  const container = document.createElement('div');
  container.id = 'x-root-container';
  const domElement = document.createElement('div');

  if (isolate || process.env.NODE_ENV === 'production') {
    const shadowRoot = container.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(domElement);
    cssInjector.setHost(shadowRoot);
  } else {
    container.appendChild(domElement);
    cssInjector.setHost(document.head);
  }

  document.body.appendChild(container);
  return domElement;
}
