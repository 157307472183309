<template>
  <div class="x" data-test="x" :dir="documentDirection">
    <SnippetConfigExtraParams :excludedExtraParams="excludedExtraParams" />
    <SnippetCallbacks />
    <PreselectedFilters />
    <Tagging />
    <UrlHandler />
    <MainModal v-if="isOpen" />
  </div>
</template>

<script lang="ts">
  import { SnippetCallbacks, SnippetConfig, use$x, XEvent } from '@empathyco/x-components';
  import { PreselectedFilters } from '@empathyco/x-components/facets';
  import { Tagging } from '@empathyco/x-components/tagging';
  import { UrlHandler } from '@empathyco/x-components/url';
  import { SnippetConfigExtraParams } from '@empathyco/x-components/extra-params';
  import { Result } from '@empathyco/x-types';
  import { QueryPreviewInfo } from '@empathyco/x-components/queries-preview';
  import { useEventListener } from '@vueuse/core';
  import {
    computed,
    ComputedRef,
    defineAsyncComponent,
    defineComponent,
    getCurrentInstance,
    inject,
    provide,
    ref,
    watch
  } from 'vue';
  import { useDevice } from './composables/use-device.composable';
  import currencies from './i18n/currencies';
  import './tailwind/index.css';
  import { isIOS, removeSearchInputFocus } from './composables/use-ios-utils-composable';

  export default defineComponent({
    components: {
      SnippetCallbacks,
      SnippetConfigExtraParams,
      Tagging,
      PreselectedFilters,
      UrlHandler,
      MainModal: defineAsyncComponent(() =>
        import('./components/custom-main-modal.vue').then(m => m.default)
      )
    },
    setup() {
      const x = use$x();
      const appInstance = getCurrentInstance();
      const { deviceName } = useDevice();
      const snippetConfig = inject<SnippetConfig>('snippetConfig')!;
      const isOpen = ref(false);

      const add2CartCallbackEvents: XEvent[] = [
        'UserClickedResultAddToCart',
        'UserClickedResultRemoveFromCart'
      ];

      x.on('SnippetCallbackExecuted', false).subscribe(payload => {
        if (add2CartCallbackEvents.includes(payload.event)) {
          const cartResult = snippetConfig.cart?.products?.find(
            product => product[(payload.payload as Result).id]
          );
          const hasStock = cartResult ? Object.values(cartResult)[0].inStock : undefined;

          if (hasStock === false) {
            x.emit('UserModifiedAdd2cart', {
              id: (payload.payload as Result).id,
              availability: false
            });
          }
        }
      });

      const openXEvents = ['UserOpenXProgrammatically', 'UserClickedOpenX'];
      openXEvents.forEach(event =>
        x.on(event as XEvent, false).subscribe(() => {
          isOpen.value = true;
        })
      );

      const documentDirection = computed(() => {
        return (
          document.documentElement.dir ||
          document.body.dir ||
          (snippetConfig.documentDirection ?? 'ltr')
        );
      });

      const excludedExtraParams = [
        'callbacks',
        'productId',
        'uiLang',
        'consent',
        'documentDirection',
        'filters',
        'isSpa',
        'queriesPreview',
        'cart',
        'wishlist'
      ];

      const currencyFormat = computed(() => currencies[snippetConfig.currency!]);
      provide<string>('currencyFormat', currencyFormat.value);

      const queriesPreviewInfo = computed(() => snippetConfig.queriesPreview ?? []);
      provide<ComputedRef<QueryPreviewInfo[]> | undefined>(
        'queriesPreviewInfo',
        queriesPreviewInfo
      );

      watch(
        () => snippetConfig.uiLang,
        uiLang => appInstance?.appContext.config.globalProperties.$setLocale(uiLang ?? 'en')
      );
      watch(deviceName, device =>
        appInstance?.appContext.config.globalProperties.$setLocaleDevice(device)
      );

      //fix keyboard issue on iOS
      if (isIOS()) {
        useEventListener(document, 'touchmove', removeSearchInputFocus);
      }

      return {
        excludedExtraParams,
        documentDirection,
        isOpen
      };
    }
  });
</script>

<style scoped>
  .x-modal :deep(.x-modal__content) {
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: auto;
  }
</style>
<style lang="scss">
  *:not(.x-keyboard-navigation *) {
    outline: none;
  }

  .x-banner,
  .x-promoted {
    &__title {
      display: none;
    }
  }

  .x-scroll:hover {
    --x-color-thumb-scroll-bar: #5f717b;
  }

  .x-main-modal {
    z-index: 10000 !important;
  }
</style>
